<template>
  <div class="container my-5">
    <b-row align-content="center" align-h="center" align-v="center">
      <h3
        class="text-center mx-2 mb-2 text-primary"
        :class="$mq == 'sm' ? 'h5' : ''"
      >
        قم بتجربة لوغو شركتك على الريبان
      </h3>
    </b-row>
    <b-row align-content="center" align-h="center" align-v="center">
      <form enctype="multipart/form-data" novalidate v-if="!isSaving">
        <div class="dropbox">
          <input
            id="fileInput"
            type="file"
            :disabled="isSaving"
            @change="filesChange($event.target.files[0])"
            accept="image/png"
            class="input-file"
          />
          <p>حمل الشعار هنا</p>
        </div>
      </form>
      <div class="col-6 mx-auto" v-if="isSaving">
        <b-progress
          :value="100"
          variant="secondary"
          striped
          animated
          class="mt-2"
        ></b-progress>
      </div>
    </b-row>
    <b-row class="mt-5">
      <b-col lg="8" sm="12" style="min-height: 225px">
        <div
          id="template"
          class="mx-auto shadow container-image1"
          v-show="isSuccess"
        >
          <img class="logo logo1" src />
          <img class="logo logo2" src />

          <img class="logo logo4" src />
        </div>
        <loader v-if="isLoading" />
      </b-col>
      <b-col
        lg="4"
        sm="12"
        v-if="isSuccess | isSaving | isLoading"
        class="mt-3"
      >
        <h4 class="text-center text-secondary">~~~~ اختر القالب ~~~~</h4>
        <b-row>
          <b-col
            ><img
              src="./../assets/ribbanCover1.jpg"
              @click="toggleTemplate"
              class="img-thumbnail img-template img-template1"
              :class="imgTemplateActive ? 'img-template-active' : ''"
          /></b-col>
          <b-col
            ><img
              src="./../assets/ribbanCover2.png"
              @click="toggleTemplate"
              class="img-thumbnail img-template"
              :class="!imgTemplateActive ? 'img-template-active' : ''"
          /></b-col>
        </b-row>
        <b-row>
          <p class="text-secondary text-center px-3 mt-3">
            <strong>تنويه:</strong> تمت محاكاة عملية الطباعة بواسطة الذكاء
            الصناعي أي أن الصور أعلاه توضح عملية الطباعة لإعطاء فكرة للعميل حول
            شكل الشعار التقرييبي على الشرائط.
          </p>
        </b-row>
        <b-row>
          <p class="text-secondary text-center px-3 mt-3">
            اذا أردت طباعة الشعار على الشرائط يمكنك التواصل معنا في أي وقت .
          </p>
          <b-button
            variant="outline-secondary"
            class="col-6 mx-auto mt-2"
            @click="whatsAppEvent"
            href="https://wa.me/971565628959?text=مرحباً ، أريد الاستفسار عن طبتعة الشعار على منتجات الشرائط "
            >{{ $t("Contact Us") }} <i class="fab fa-whatsapp"> </i
          ></b-button>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_LOADING = 3;

import loader from "../components/widgets/loader.vue";

export default {
  metaInfo: {
    title: "Ribban & Logo",
  },
  components: {
    loader,
  },
  data() {
    return {
      currentStatus: null,
      loader: true,
      imgTemplateActive: true,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isLoading() {
      return this.currentStatus === STATUS_LOADING;
    },
  },
  methods: {
    runLoader() {
      this.currentStatus = STATUS_LOADING;
      setTimeout(() => {
        this.currentStatus = STATUS_SUCCESS;
      }, 2000);
    },
    toggleTemplate(event) {
      this.$gtag.event("E_RibbanTemplateChanged", { method: "Google" });

      this.runLoader();
      var containerImage = document.getElementById("template");
      if (event.target.classList.contains("img-template1")) {
        this.imgTemplateActive = true;
        containerImage.classList.remove("container-image2");
        containerImage.classList.add("container-image1");
      } else {
        this.imgTemplateActive = false;

        containerImage.classList.remove("container-image1");
        containerImage.classList.add("container-image2");
      }
    },

    filesChange(fileSrc) {
      this.$gtag.event("E_RibbanLogoChanged", { method: "Google" });

      var logos = document.getElementsByClassName("logo");
      this.currentStatus = STATUS_SAVING;

      setTimeout(() => {
        this.currentStatus = STATUS_SUCCESS;

        for (var i = 0; i < logos.length; i++) {
          logos[i].src = URL.createObjectURL(fileSrc);
        }
      }, 2000);
    },
    whatsAppEvent() {
      this.$gtag.event("E_WhatsAPP", { method: "Google" });
    },
  },
  mounted() {
    // this.$gtag.event("add logo to ribban page", { method: "Google" });
    this.currentStatus = STATUS_INITIAL;
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.dropbox {
  border: 1px solid #92278f;
  border-radius: 5px;
  background: transparent;
  color: #92278f;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  height: 50px;
  position: absolute;
  cursor: pointer;
  margin-top: 10px;
  left: 0;
  right: 0;
}

.dropbox:hover {
  background: #92278f;
  color: #fff;
}

.dropbox p {
  margin: 0;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#template {
  animation: fadeIn 3s;
}
@media (max-width: 600px) {
  .container-image1 {
    width: 300px !important;
    height: 225px !important;
  }
  .container-image2 {
    width: 300px !important;
    height: 225px !important;
  }
  .logo {
    width: 50px !important;
    max-height: 20px !important;
  }

  .container-image1 .logo.logo1 {
    bottom: 82px !important;
    left: 17px !important;
  }

  .container-image1 .logo.logo2 {
    bottom: 73px !important;
    left: 107px !important;
  }

  .container-image1 .logo.logo3 {
    bottom: 76px !important;
    left: 144px !important;
  }

  .container-image1 .logo.logo4 {
    bottom: 89px !important;
    left: 205px !important;
  }

  .container-image2 .logo.logo1 {
    bottom: 175px !important;
    left: 112px !important;
  }

  .container-image2 .logo.logo2 {
    bottom: 89px !important;
    left: 80px !important;
  }

  .container-image2 .logo.logo3 {
    bottom: 26px !important;
    left: 80px !important;
  }

  .container-image2 .logo.logo4 {
    bottom: 16px !important;
    left: 158px !important;
  }
}

.container-image1 {
  position: relative;
  width: 600px;
  height: 450px;
  background-image: url("./../assets/ribbanCover1.jpg");
  background-size: cover;
  overflow: hidden;
}

.container-image1 .logo {
  position: absolute;
  filter: brightness(0) invert(1);
  width: 100px;
  max-height: 40px;
}

.container-image1 .logo.logo1 {
  transform: rotateZ(14deg);
  bottom: 163px;
  left: 30px;
}

.container-image1 .logo.logo2 {
  transform: rotateZ(-1deg);
  bottom: 146px;
  left: 215px;
}

.container-image1 .logo.logo3 {
  transform: rotateZ(-5deg);
  bottom: 152px;
  left: 285px;
}

.container-image1 .logo.logo4 {
  transform: rotateZ(342deg);
  bottom: 178px;
  left: 410px;
  clip-path: polygon(0 0, 61% 0, 50% 100%, 0 100%);
}

.container-image2 {
  position: relative;
  width: 600px;
  height: 450px;
  background-image: url("./../assets/ribbanCover2.png");
  background-size: cover;
  overflow: hidden;
}

.logo {
  position: absolute;
  filter: brightness(0) invert(1);
  width: 100px;
  max-height: 40px;
}

.container-image2 .logo.logo1 {
  transform: rotateZ(-63deg);
  bottom: 351px;
  left: 224px;
}

.container-image2 .logo.logo2 {
  transform: rotateZ(282deg);
  bottom: 176px;
  left: 159px;
}

.container-image2 .logo.logo3 {
  transform: rotateZ(268deg);
  bottom: 54px;
  left: 159px;
  clip-path: polygon(59% 0, 100% 0, 100% 100%, 17% 100%);
}

.container-image2 .logo.logo4 {
  transform: rotateZ(301deg);
  bottom: 33px;
  left: 312px;
  // clip-path: polygon(0 0, 30% 0, 25% 100%, 0 100%);
}

.img-template {
  cursor: pointer;
}
.img-template-active {
  border: 1px solid #92278f;
  box-shadow: 0px 0rem 1rem 4px rgb(181 0 255 / 15%) !important;
}
</style>